// ISO 639-1
// https://www.rfc-editor.org/rfc/rfc5646
const en_CA = "en-CA";
const fr_CA = "fr-CA";
const CAD = "CAD";

const en_CA_non_ISO = "en_CA";
const fr_CA_non_ISO = "fr_CA";

export function useLocale() {
    return en_CA;
}

export function useLanguage() {
    return en_CA.substring(0, 2);
}

export function useCurrency() {
    return CAD;
}

export function useNonIsoLocale() {
    return en_CA_non_ISO;
}

export function Translate(params) {
    const string = params.string;
    const locale = useLocale();

    if (string)
        return translate(string, locale);
    else
        return translate("na", locale);
}

export function hasTranslation(key, l) {
    const entry = strings[key];
    return !entry ? false : true;
}

export function translate(key, l) {
    const locale = sanitizeLocale(l);
    const entry = strings[key];
    if (!entry) {
        console.error(`Translation entry for '${key}' not found in the dictionary.`);
        return strings["na"][locale];
    }
    return strings[key][locale];
}

function sanitizeLocale(locale) {
    if (!locale || locale.length !== 5) {
        return "en_CA";
    }

    if (locale[2] === "-") {
        return locale.replace('-', '_');
    }

    if (locale !== "en_CA" && locale !== "fr_CA") {
        return "en_CA";
    }
}

export function formatAsCurrency(amount, locale, currency) {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
    });

    return formatter.format(amount);
}

export function getLocale() {
    if (!document.cookie.split(';').some((entry) => entry.trim().startsWith('locale='))) {
        setLocale(en_CA);
    }
    return document.cookie.split('; ').find((row) => row.startsWith('locale='))?.split('=')[1];
}

export function setLocale(locale) {
    console.log("Setting locale to " + locale);
    document.cookie = `locale=${locale}; SameSite=None; Secure`;
}

const strings = {
    "in-store-only": {
        en_CA: "Available for in-store purchase only",
        fr_CA: "Disponible pour achat en magasin seulement"
    },
    "website-name": {
        en_CA: "Tire Spy",
        fr_CA: "Tire Spy"
    },
    "loading-ellipsis": {
        en_CA: "Loading...",
        fr_CA: "Loading..."
    },
    "model": {
        en_CA: "Model",
        fr_CA: "Model"
    },
    "na": {
        en_CA: "N/A",
        fr_CA: "N/A"
    },
    "price": {
        en_CA: "Price",
        fr_CA: "Price"
    },

    "buy-button-default-text": {
        en_CA: "View at Canadian Tire",
        fr_CA: "View at Canadian Tire"
    },

    "home-title": {
        en_CA: "Welcome to Tire Spy, a free Canadian Tire price tracker!",
        fr_CA: "Welcome to Tire Spy, a free Canadian Tire price tracker!"
    },
    "home-description-1": {
        en_CA: "Have you ever timed a Canadian Tire purchase because you know products always go on sale?",
        fr_CA: "Have you ever timed a Canadian Tire purchase because you know products always go on sale?"
    },
    "home-description-2": {
        en_CA: "Our free Canadian Tire price tracker monitors thousands of products and alerts you when prices drop so you don't have to waste your time going through flyers every week!",
        fr_CA: "Our free Canadian Tire price tracker monitors thousands of products and alerts you when prices drop so you don't have to waste your time going through flyers every week!"
    },
    "home-description-3": {
        en_CA: "Start by finding products or exploring current deals:",
        fr_CA: "Start by finding products a product or exploring current deals:"
    },

    "home-promo-title-1": {
        en_CA: "Price Drop Alerts",
        fr_CA: "Price Drop Alerts"
    },
    "home-promo-subtitle-1": {
        en_CA: "Create price watches and get alerts via email when prices drop at Canadian Tire.",
        fr_CA: "Create price watches and get alerts via email when prices drop at Canadian Tire."
    },
    "home-promo-title-2": {
        en_CA: "Price History Charts",
        fr_CA: "Price History Charts"
    },
    "home-promo-subtitle-2": {
        en_CA: "View the price history of thousands of Canadian Tire products.",
        fr_CA: "View the price history of thousands of Canadian Tire products."
    },

    "navbar-search": {
        en_CA: "Find Products",
        fr_CA: "Find Products"
    },
    "navbar-popular-products": {
        en_CA: "Popular Products",
        fr_CA: "Popular Products"
    },
    "navbar-top-price-drops": {
        en_CA: "Top Price Drops",
        fr_CA: "Top Price Drops"
    },

    "footer-home": {
        en_CA: "Home",
        fr_CA: "Home"
    },
    "footer-about": {
        en_CA: "About",
        fr_CA: "About"
    },
    "footer-blog": {
        en_CA: "Blog",
        fr_CA: "Blog"
    },
    "footer-features": {
        en_CA: "Features",
        fr_CA: "Features"
    },
    "footer-tos": {
        en_CA: "Terms",
        fr_CA: "Terms"
    },
    "footer-privacy": {
        en_CA: "Privacy",
        fr_CA: "Privacy"
    },
    "footer-support-us": {
        en_CA: "Support Us",
        fr_CA: "Support Us"
    },
    "footer-help": {
        en_CA: "Help",
        fr_CA: "Help"
    },
    "footer-all-rights-reserved": {
        en_CA: "All rights reserved.",
        fr_CA: "All rights reserved."
    },

    "error-page-header": {
        en_CA: "Oops!",
        fr_CA: "Oops!"
    },
    "error-page-description": {
        en_CA: "Sorry, an unexpected error has occurred.",
        fr_CA: "Sorry, an unexpected error has occurred."
    },

    "top-price-drops-title": {
        en_CA: "Top Price Drops",
        fr_CA: "Top Price Drops"
    },
    "top-price-drops-type-desc": {
        en_CA: "Price drop type",
        fr_CA: "Price drop type"
    },
    "top-price-drops-type-0-name": {
        en_CA: "Lowest On Record",
        fr_CA: "Lowest On Record"
    },
    "top-price-drops-type-0-desc": {
        en_CA: "Lowest prices seen in the last 12 months.",
        fr_CA: "Lowest prices seen in the last 12 months.",
    },
    "top-price-drops-type-1-name": {
        en_CA: "Biggest Drops",
        fr_CA: "Biggest Drops"
    },
    "top-price-drops-type-1-desc": {
        en_CA: "The biggest absolute price drops.",
        fr_CA: "The biggest absolute price drops.",
    },
    "top-price-drops-type-2-name": {
        en_CA: "Biggest Drops (%)",
        fr_CA: "Biggest Drops (%)"
    },
    "top-price-drops-type-2-desc": {
        en_CA: "The biggest relative (%) price drops.",
        fr_CA: "The biggest relative (%) price drops.",
    },
    "top-price-drops-categories-ddl-aria-label": {
        en_CA: "Filter by category",
        fr_CA: "Filter by category",
    },
    "top-price-drops-categories-ddl-all": {
        en_CA: "All Categories",
        fr_CA: "All Categories",
    },

    "modal-window-close": {
        en_CA: "Close",
        fr_CA: "Close"
    },

    "alert-close": {
        en_CA: "Close",
        fr_CA: "Close"
    },

    "product-price-watch-instructions": {
        en_CA: "Sign up for price drop alerts and begin tracking this product by completing the form below.",
        fr_CA: "Sign up for price drop alerts and begin tracking this product by completing the form below."
    },
    "product-price-watch-title": {
        en_CA: "Create a Canadian Tire Price Watch",
        fr_CA: "Create a Canadian Tire Price Watch"
    },
    "product-price-watch-track-sm": {
        en_CA: "Track!",
        fr_CA: "Track!"
    },
    "product-price-watch-track-lg": {
        en_CA: "Start Tracking",
        fr_CA: "Start Tracking"
    },
    "product-price-watch-email-label": {
        en_CA: "We'll send an email to:",
        fr_CA: "We'll send an email to:"
    },
    "product-price-watch-email-aria-label": {
        en_CA: "Email address",
        fr_CA: "Email address"
    },
    "product-price-watch-price-label": {
        en_CA: "When the Canadian Tire price drops to:",
        fr_CA: "When the Canadian Tire price drops to:"
    },
    "product-price-watch-price-aria-label": {
        en_CA: "Price",
        fr_CA: "Price"
    },
    "product-price-watch-email-invalid": {
        en_CA: "Please provide a valid email address.",
        fr_CA: "Please provide a valid email address."
    },
    "product-price-watch-price-invalid": {
        en_CA: "Please provide a valid price.",
        fr_CA: "Please provide a valid price."
    },
    "product-price-watch-success": {
        en_CA: "Awesome, you're all set - we'll send you an email when the price drops.",
        fr_CA: "Awesome, you're all set - we'll send you an email when the price drops.",
    },
    "product-price-watch-failure": {
        en_CA: "An error occurred when creating your Price Watch, please try again later or reach out to us for support.",
        fr_CA: "An error occurred when creating your Price Watch, please try again later or reach out to us for support."
    },
    "product-price-watch-failure-details-label": {
        en_CA: "Details:",
        fr_CA: "Details:"
    },
    "product-price-watch-any": {
        en_CA: "Any Price Drop",
        fr_CA: "Any Price Drop"
    },
    "product-price-watch-good": {
        en_CA: "Good Deal",
        fr_CA: "Good Deal"
    },
    "product-price-watch-best": {
        en_CA: "Best Price",
        fr_CA: "Best Price"
    },

    "product-price-history-title": {
        en_CA: "Canadian Tire Price History",
        fr_CA: "Canadian Tire Price History"
    },
    "product-price-as-of": {
        en_CA: "Price as of",
        fr_CA: "Price as of"
    },
    "product-price-store": {
        en_CA: "Store #",
        fr_CA: "Store #"
    },
    "product-price-tooltip": {
        en_CA: "Product prices and availability are accurate as of the date/time and store number indicated and are subject to change. Prices can vary between Canadian Tire locations across Canada. Any price and availability information displayed on Canadian Tire at the time of purchase will apply to the purchase of a product.", // Tire Spy receives a commission on all purchases made through our affiliate links.
        fr_CA: "Product prices and availability are accurate as of the date/time and store number indicated and are subject to change. Prices can vary between Canadian Tire locations across Canada. Any price and availability information displayed on Canadian Tire at the time of purchase will apply to the purchase of a product." // Tire Spy receives a commission on all purchases made through our affiliate links.
    },
    "product-search-title": {
        en_CA: "Search Results",
        fr_CA: "Search Results"
    },
    "product-search-loading": {
        en_CA: "Loading results...", // Find products to track by keyword, manufacturer, or model number.
        fr_CA: "Loading results..." // Find products to track by keyword, manufacturer, or model number.
    },
    "product-search-no-query": {
        en_CA: "Please enter a search term in the 'Find Products' search box above.",
        fr_CA: "Please enter a search term in the 'Find Products' search box above."
    },
    "product-search-engine-error": {
        en_CA: "The search engine returned invalid results.",
        fr_CA: "The search engine returned invalid results."
    },
    "product-search-no-results": {
        en_CA: "Sorry, we didn't find any results for your search. Maybe try a less specific search term.",
        fr_CA: "Sorry, we didn't find any results for your search. Maybe try a less specific search term."
    },
    "product-variants-title": {
        en_CA: "Product Variants",
        fr_CA: "Product Variants"
    },
    "product-details-title": {
        en_CA: "Product Details",
        fr_CA: "Product Details"
    },
    "product-details-code": {
        en_CA: "Code",
        fr_CA: "Code"
    },
    "product-details-category": {
        en_CA: "Category",
        fr_CA: "Category"
    },
    "product-details-manufacturer": {
        en_CA: "Manufacturer",
        fr_CA: "Manufacturer"
    },
    "product-details-model": {
        en_CA: "Model",
        fr_CA: "Model"
    },
    "product-details-price": {
        en_CA: "List Price",
        fr_CA: "List Price"
    },
    "product-details-last-update": {
        en_CA: "Last Price Scan",
        fr_CA: "Last Price Scan"
    },

    "product-list-toolbar-filter-by-select": {
        en_CA: "Filter by select",
        fr_CA: "Filter by select"
    },
    "product-list-toolbar-filter-by": {
        en_CA: "Filter by",
        fr_CA: "Filter by"
    },
    "product-list-toolbar-filter-by-no-filter": {
        en_CA: "All",
        fr_CA: "All"
    },

    "product-list-toolbar-order-by-select": {
        en_CA: "Order by select",
        fr_CA: "Order by select"
    },
    "product-list-toolbar-order-by": {
        en_CA: "Order by",
        fr_CA: "Order by"
    },
    "product-list-toolbar-order-by-relevance": {
        en_CA: "Relevance",
        fr_CA: "Relevance"
    },
    "product-list-toolbar-order-by-price-low-to-high": {
        en_CA: "Price: low to high",
        fr_CA: "Price: low to high"
    },
    "product-list-toolbar-order-by-price-high-to-low": {
        en_CA: "Price: high to low",
        fr_CA: "Price: high to low"
    },
    "product-list-toolbar-order-by-title": {
        en_CA: "Product name",
        fr_CA: "Product name"
    },

    "product-list-toolbar-order-by-discount-pct-asc": {
        en_CA: "Top Drops (%, low to high)",
        fr_CA: "Top Drops (%, low to high)"
    },
    "product-list-toolbar-order-by-discount-pct-dsc": {
        en_CA: "Top Drops (%)",
        fr_CA: "Top Drops (%)"
    },
    "product-list-toolbar-order-by-discount-val-asc": {
        en_CA: "Top Drops (low to high)",
        fr_CA: "Top Drops (low to high)"
    },
    "product-list-toolbar-order-by-discount-val-dsc": {
        en_CA: "Top Drops",
        fr_CA: "Top Drops"
    },

    "product-list-department-and-category-dept": {
        en_CA: "Department",
        fr_CA: "Department"
    },
    "product-list-department-and-category-dept-select": {
        en_CA: "Department select",
        fr_CA: "Department select"
    },
    "product-list-department-and-category-all-depts": {
        en_CA: "All Departments",
        fr_CA: "All Departments"
    },
    "product-list-department-and-category-cat": {
        en_CA: "Category",
        fr_CA: "Category"
    },
    "product-list-department-and-category-cat-select": {
        en_CA: "Category select",
        fr_CA: "Category select"
    },
    "product-list-department-and-category-all-cats": {
        en_CA: "All Categories",
        fr_CA: "All Categories"
    },
    "product-list-no-results": {
        en_CA: "Sorry, we didn't find any results. Maybe try different criteria.",
        fr_CA: "Sorry, we didn't find any results. Maybe try different criteria."
    },

    "product-grid-min-price-drop-label": {
        en_CA: "Minimum Drop",
        fr_CA: "Minimum Drop"
    },
    "product-grid-min-price-drop-select": {
        en_CA: "Minimum drop select",
        fr_CA: "Minimum drop select"
    },
    "product-grid-min-price-drop-any": {
        en_CA: "Any price drop",
        fr_CA: "Any price drop"
    },
    "product-grid-min-price-drop-low": {
        en_CA: "Best price on record",
        fr_CA: "Best price on record"
    },
    "product-grid-prev-price": {
        en_CA: "Previously: ",
        fr_CA: "Previously: "
    },
    "product-grid-save": {
        en_CA: "Save: ",
        fr_CA: "Save: "
    },
    "product-grid-buy-button-text": {
        en_CA: "Buy Now",
        fr_CA: "Buy Now"
    },

    "category-loading-title": {
        en_CA: "Loading products...",
        fr_CA: "Loading products..."
    },
    "category-loading-subtitle": {
        en_CA: "Please wait while we load the products under this category.",
        fr_CA: "Please wait while we load the products under this category."
    },
    "category-invalid-title": {
        en_CA: "Unknown category",
        fr_CA: "Unknown category"
    },
    "category-invalid-subtitle": {
        en_CA: "The category you're looking for is unknown or no longer valid!",
        fr_CA: "The category you're looking for is unknown or no longer valid!"
    },
    "category-no-results": {
        en_CA: "No products found under this category.",
        fr_CA: "No products found under this category."
    },

    "manufacturer-loading-subtitle": {
        en_CA: "Loading products...",
        fr_CA: "Loading products..."
    },
    "manufacturer-no-results": {
        en_CA: "No products found for this manufacturer.",
        fr_CA: "No products found for this manufacturer."
    },

    "product-list-invalid-page-number": {
        en_CA: "Invalid page number!",
        fr_CA: "Invalid page number!"
    },

    "pagination-navigation": {
        en_CA: "Page navigation",
        fr_CA: "Page navigation"
    },
    "pagination-next": {
        en_CA: "Next",
        fr_CA: "Next"
    },
    "pagination-prev": {
        en_CA: "Previous",
        fr_CA: "Previous"
    },

    "price-badges-off": {
        en_CA: "off",
        fr_CA: "off"
    },
    "price-badges-best-price": {
        en_CA: "Best Price",
        fr_CA: "Best Price"
    },

    "unsubscribe-working": {
        en_CA: "Please wait while we work on your request...",
        fr_CA: "Please wait while we work on your request..."
    },
    "unsubscribe-success": {
        en_CA: "You've been unsubscribed from this Price Watch.",
        fr_CA: "You've been unsubscribed from this Price Watch."
    },
    "unsubscribe-failure": {
        en_CA: "Unable to unsubscribe",
        fr_CA: "Unable to unsubscribe"
    },

    "referral-line1": {
        en_CA: "We are taking you to your product at Canadian Tire.",
        fr_CA: "We are taking you to your product at Canadian Tire."
    },
    "referral-line2": {
        en_CA: "Thank you for using Tire Spy, a free Canadian Tire price tracker. We continuously monitor Canadian Tire prices to help you save money. View our Canadian Tire price history charts to inform purchasing decisions. Set Canadian Tire price drop alerts and be notified when prices drop at Canadian Tire.",
        fr_CA: "Thank you for using Tire Spy, a free Canadian Tire price tracker. We continuously monitor Canadian Tire prices to help you save money. View our Canadian Tire price history charts to inform purchasing decisions. Set Canadian Tire price drop alerts and be notified when prices drop at Canadian Tire."
    },
    "referral-button": {
        en_CA: "Click here if you are not redirected",
        fr_CA: "Click here if you are not redirected"
    },
    "referral-error-line1": {
        en_CA: "Sorry, we have encountered an error while processing your request: the parameters provided are corrupted or no longer valid.",
        fr_CA: "Sorry, we have encountered an error while processing your request: the parameters provided are corrupted or no longer valid.",
    },
    "referral-error-line2": {
        en_CA: "Please visit our website to view our Canadian Tire price history charts to inform purchasing decisions. Set Canadian Tire price drop alerts and be notified when prices drop at Canadian Tire.",
        fr_CA: "Please visit our website to view our Canadian Tire price history charts to inform purchasing decisions. Set Canadian Tire price drop alerts and be notified when prices drop at Canadian Tire.",
    },
    "referral-error-button": {
        en_CA: "Visit our website",
        fr_CA: "Visit our website"
    },

    "support-us-title": {
        en_CA: "Donations",
        fr_CA: "Donations"
    },
    "support-us-body-1": {
        en_CA: "At Tire Spy we strive to provide you with a valuable and free service to track prices at Canadian Tire. We understand that finding the best deals on Canadian Tire can be a daunting task, which is why we've created an easy-to-use platform to help you make informed purchasing decisions.",
        fr_CA: "At Tire Spy we strive to provide you with a valuable and free service to track prices at Canadian Tire. We understand that finding the best deals on Canadian Tire can be a daunting task, which is why we've created an easy-to-use platform to help you make informed purchasing decisions."
    },
    /*
    "support-us-body-2": {
        en_CA: "Like many other price tracking platforms, we rely on affiliate commissions to sustain our operations. While we greatly appreciate your support through purchasing products via our affiliate links, commissions are often minimal and retailers have been steadily reducing these rates making it increasingly challenging for us to keep the lights on.",
        fr_CA: "Like many other price tracking platforms, we rely on affiliate commissions to sustain our operations. While we greatly appreciate your support through purchasing products via our affiliate links, commissions are often minimal and retailers have been steadily reducing these rates making it increasingly challenging for us to keep the lights on."
    },
    */
    "support-us-body-3": {
        en_CA: "Donate With PayPal",
        fr_CA: "Donate With PayPal"
    },
    "support-us-body-4": {
        en_CA: "Your generous support will play a vital role in ensuring that Tire Spy remains accessible and free to use for the long term.",
        fr_CA: "Your generous support will play a vital role in ensuring that Tire Spy remains accessible and free to use for the long term."
    },
    "support-us-body-5": {
        en_CA: "Click Donate to make a one-time donation or to set up a recurring monthly donation.",
        fr_CA: "Click Donate to make a one-time donation or to set up a recurring monthly donation."
    },
    "support-us-paypal-button": {
        en_CA: "Donate with PayPal button",
        fr_CA: "Bouton Faire un don avec PayPal"
    },

    "about-title": {
        en_CA: "About Tire Spy",
        fr_CA: "About Tire Spy"
    },
    "about-description-1": {
        en_CA: "We are a Canadian Tire price tracker that provides price drop alerts and price history charts for products sold by Canadian Tire.",
        fr_CA: "We are a Canadian Tire price tracker that provides price drop alerts and price history charts for products sold by Canadian Tire."
    },
    "about-description-2": {
        en_CA: "Want to get in touch? Reach out to us on Twitter or message us directly using the form below.",
        fr_CA: "Want to get in touch? Reach out to us on Twitter or message us directly using the form below."
    },

    "terms-title": {
        en_CA: "Terms of Service",
        fr_CA: "Terms of Service"
    },

    "privacy-title": {
        en_CA: "Privacy Policy",
        fr_CA: "Privacy Policy"
    },

    "help-title": {
        en_CA: "Support",
        fr_CA: "Support"
    },
    "help-description-1": {
        en_CA: "We're here to help if you need support or are having a technical issue with the website.",
        fr_CA: "We're here to help if you need support or are having a technical issue with the website."
    },
    "help-description-2": {
        en_CA: "Reach out to us on Twitter or message us directly using the form below.",
        fr_CA: "Reach out to us on Twitter or message us directly using the form below."
    },
    
    "contact-twitter-title": {
        en_CA: "X (former Twitter)",
        fr_CA: "X (former Twitter)"
    },
    "contact-twitter-description": {
        en_CA: "We're on X (former Twitter)",
        fr_CA: "We're on X (former Twitter)"
    },
    "contact-form-title": {
        en_CA: "Message us directly",
        fr_CA: "Message us directly"
    },
    "contact-form-description": {
        en_CA: "Please tell us your name, email address and how we can help.",
        fr_CA: "Please tell us your name, email address and how we can help."
    },
    "contact-form-name-label": {
        en_CA: "Your Name",
        fr_CA: "Your Name"
    },
    "contact-form-name-invalid": {
        en_CA: "Please tell us your name.",
        fr_CA: "Please tell us your name."
    },
    "contact-form-email-label": {
        en_CA: "Your Email Address",
        fr_CA: "Your Email Address"
    },
    "contact-form-email-invalid": {
        en_CA: "Please tell us your email address.",
        fr_CA: "Please tell us your email address."
    },
    "contact-form-message-label": {
        en_CA: "How can we help?",
        fr_CA: "How can we help?"
    },
    "contact-form-message-invalid": {
        en_CA: "Please tell us how we can help.",
        fr_CA: "Please tell us how we can help."
    },
    "contact-form-button-label": {
        en_CA: "Send Message",
        fr_CA: "Send Message"
    },
    "contact-form-error": {
        en_CA: "An error occoured while sending your message. Please try again later or reach out to us on Twitter.",
        fr_CA: "An error occoured while sending your message. Please try again later or reach out to us on Twitter."
    },
    "contact-form-success": {
        en_CA: "Your message has been sent. We'll be in touch as soon as possible!",
        fr_CA: "Your message has been sent. We'll be in touch as soon as possible!"
    },

    "firebase-function-proxy-unsubscribe-invalid-args": {
        en_CA: "The Price Watch reference is invalid.",
        fr_CA: "The Price Watch reference is invalid."
    },
    "firebase-function-app-check-error": {
        en_CA: "The function must be called from an App Check verified app.",
        fr_CA: "The function must be called from an App Check verified app."
    },
    "firebase-function-invalid-args-error": {
        en_CA: "The function must be called with valid arguments.",
        fr_CA: "The function must be called with valid arguments."
    },
    "firebase-function-unsubscribe-price-watch-not-found": {
        en_CA: "The requested Price Watch is invalid or you've already been unsubscribed.",
        fr_CA: "The requested Price Watch is invalid or you've already been unsubscribed."
    },
    "firebase-function-unsubscribe-permission-denied": {
        en_CA: "You don't have permission to unsubscribe from this Price Watch.",
        fr_CA: "You don't have permission to unsubscribe from this Price Watch."
    },
};
